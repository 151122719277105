import {
  ActionNames,
  ReferralInfo,
  serviceListReferralInfo,
} from '../../utils/bi/consts';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigationTarget } from './consts';
import { bookingsBookItClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import { getLocationId } from '../../utils/getLocationId/getLocationId';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { Basket } from '@wix/bookings-services-preferences-modal/types';
import {
  navigateToBookingsCalendarPage,
  navigateToBookingsServicePage,
} from '@wix/bookings-catalog-calendar-viewer-navigation';
import { trackAnalytics } from '@wix/bookings-catalog-calendar-viewer-utils';
import { EnrichedService } from '../../types/types';
import { ServiceListContext } from '../../context/createServiceListContext';
import { getTrackingInfoForNavigateToServicePageClick } from '@wix/bookings-calendar-catalog-viewer-analytics-adapter';

export type NavigateToNextPageAction = ({
  service,
  target,
  referralInfo,
  basket,
}: {
  service?: EnrichedService;
  target: NavigationTarget;
  referralInfo?: ReferralInfo;
  basket?: Basket;
}) => Promise<void>;

export const createNavigateToNextPageAction = ({
  flowAPI,
  serviceListContext,
  widgetViewModel,
}: {
  flowAPI: ControllerFlowAPI;
  serviceListContext: ServiceListContext;
  widgetViewModel: WidgetViewModel;
}): NavigateToNextPageAction => {
  const {
    bi,
    controllerConfig: { wixCodeApi, platformAPIs },
    experiments,
  } = flowAPI;
  const { businessInfo } = serviceListContext;

  return async ({ basket, service, target, referralInfo }) => {
    const queryParam = getResourceQueryParam(wixCodeApi);
    const locationId = getLocationId(flowAPI, widgetViewModel);
    const optionalParams = {
      referral: serviceListReferralInfo,
      ...(queryParam.staff ? { staff: queryParam.staff } : {}),
      ...(queryParam.resource ? { resource: queryParam.resource } : {}),
      ...(locationId ? { location: locationId } : {}),
    };

    if (!service) {
      if (!basket) {
        console.error('both service and basket are missing');
        return;
      }
      return navigateToBookingsCalendarPage(
        flowAPI.controllerConfig.wixCodeApi,
        flowAPI.controllerConfig.platformAPIs,
        {
          serviceSlugOrBasket: basket,
          navigationContext: optionalParams,
        },
      );
    }
    const data = {
      service,
      businessName: businessInfo.name,
    };

    if (target === NavigationTarget.SERVICE_PAGE) {
      const trackingInfo = getTrackingInfoForNavigateToServicePageClick(data);

      trackAnalytics({
        wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
        eventId: trackingInfo.eventId as any,
        payload: trackingInfo.payload,
      });
    }

    bi?.report(
      bookingsBookItClick({
        service_id: service.id!,
        type: service.type!,
        isPendingApproval: service.onlineBooking?.requireManualApproval!,
        referralInfo,
        actionName: ActionNames[target],
      }),
    );
    try {
      switch (target) {
        case NavigationTarget.CALENDAR_PAGE:
          return navigateToBookingsCalendarPage(wixCodeApi, platformAPIs, {
            serviceSlugOrBasket: service.mainSlug?.name!,
            navigationContext: optionalParams,
          });

        case NavigationTarget.SERVICE_PAGE:
          return navigateToBookingsServicePage(
            wixCodeApi,
            {
              serviceSlug: service.dummy ? '' : service.mainSlug?.name!,
              ...optionalParams,
            },
            experiments.enabled('specs.bookings.useCustomUrlSegmentsForSlug'),
          );
      }
    } catch (e) {
      console.error(e);
    }
  };
};
